<template>
  <div class="page">
    <div class="tools-bar">
      <el-button-group>
        <el-button @click="load">刷新</el-button>
        <el-button @click="exportVisible = true">导出</el-button>
      </el-button-group>

      <el-select class="mrl-10" v-model="status" placeholder="请选择" @change="load">
        <el-option
          v-for="item in ['正常', '所有','待支付', '申请退款中', '待发货', '待收货', '已完成',  '已退款', '已取消']"
          :key="item" :label="item" :value="item"/>
      </el-select>

      <div class="grow"></div>

      <el-input placeholder="搜索" v-model="inputSearch" style="width: 230px"
                @keyup.enter.native="onSearch" @clear="load" clearable>
        <el-button slot="append" icon="el-icon-search" @click="onSearch"/>
      </el-input>
    </div>

    <el-table class="table"
              v-loading="loading"
              :data="tableData"
              element-loading-text="获取数据中 ..."
              @row-click="onClickRow"
              stripe
              highlight-current-row>
      <el-table-column align="center" label="ID" width="90" prop="id"/>
<!--      <el-table-column label="类型" width="60" prop="type"/>-->
      <el-table-column label="状态" width="90" prop="status">
        <template slot-scope="scope">
          <div class="tags is-inline">
            <!--              <order-status v-model="scope.row.status"/>-->
            <!--'待提交', '待付款', '待发货', '已发货', '已完成',  '申请退款中',  '已退款', '已取消' -->
            <el-tag v-if="scope.row.status === '待发货'" type="warning">待发货</el-tag>

            <el-tag v-else-if="scope.row.status === '待支付'"
                    style="background-color: rgba(74, 85, 115, 1);color: #fff;border: none;">待支付
            </el-tag>

            <el-tag v-else-if="scope.row.status === '已取消'" type="info">已取消</el-tag>
            <el-tag v-else-if="scope.row.status === '已退款'"
                    style="background-color: rgba(141, 66, 82, 0.7);color: #fff;border: none;">已退款
            </el-tag>
            <el-tag v-else-if="scope.row.status === '待收货'"
                    style="background-color: rgba(74, 85, 115, 0.5);color: #fff;border: none;">待收货
            </el-tag>
            <el-tag v-else-if="scope.row.status === '已完成'" type="success">已完成</el-tag>
            <el-tag v-else-if="scope.row.status === '申请退款中'" type="danger">申请退款中</el-tag>
            <el-tag v-else>{{ scope.row.status}}</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="订单用户" width="100" >
          <template slot-scope="scope">
            <xid-link :xid="'user-' + scope.row.user.id"
                      :name="scope.row.user.realname || scope.row.user.nickname"/>
          </template>
      </el-table-column>

      <el-table-column label="收货人" width="100" prop="recv_name"/>
      <el-table-column label="手机" width="130" prop="recv_tel"/>
      <el-table-column label="商品" prop="title">
        <template slot-scope="scope">
          <div class="tags is-inline">
            <el-tag v-for="g in scope.row.goods"
                    :key="Math.random()">{{ g.title }} x {{ g.num }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="支付金额" width="90" prop="fee_paid"/>
      <el-table-column label="时间" width="160" prop="datetime_created"/>

    </el-table>

    <el-pagination class="pagination"
                   layout="total, prev, pager, next"
                   @current-change="load"
                   :current-page.sync="page"
                   :page-size="30"
                   :total="total"/>

    <el-dialog title="导出" width="500px" :visible.sync="exportVisible">

      <el-form>
        <el-form-item label="导出范围">仅导出【已付款、发货、完成】的订单</el-form-item>
        <el-form-item label="时间范围">
          <el-date-picker
            v-model="export_date_range"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      </el-form>

      <footer slot="footer">
        <el-button @click="onExport" :loading="export_loading" type="primary">导出</el-button>
        <el-button @click="onCancelExport">取消</el-button>
      </footer>
    </el-dialog>

  </div>
</template>

<script>

  import { export_json_to_excel } from '../plugins/Export2Excel'
  import XidLink from "@/comps/xid-link";

  export default {
    components: {XidLink},
    data() {
      return {
        exportVisible: false,
        export_date_range: [],
        export_loading: false,
        inputSearch: '',
        tableData: [],
        page: 1,
        total: 1,
        loading: false,
        status: '正常'
      }
    },
    mounted() {
      this.load()
    },
    watch: {
      inputSearch(v){
        if (!v) {
          this.page = 1
          this.load()
        }
      }
    },

    methods: {
      load() {
        this.loading = true
        this.$api.request('/order/list_with_page', {
          page: this.page,
          filter_status: this.status
        }).then(data => {
          this.tableData = data.contains
          this.page = data.page
          this.total = data.total
          this.loading = false
          window.scrollTo(0, 0)
        })
      },
      onClickRow({id}) {
        this.$router.push({name: 'order-info', query: {id}})
      },
      onSearch(e) {
        let msg = this.$message.info('正在搜索')
        this.loading = true
        this.$api.request('/order/search', {key: this.inputSearch}).then(data => {
          msg.close()
          this.loading = false
          this.tableData = data
          this.page = 1
          this.total = 0
        })
      },
      onCancelExport() {
        this.exportVisible = false
        this.export_loading = false
      },
      onExport() {
        if (this.export_date_range.length !== 2) {
          return alert('请选择导出数据的时间范围')
        }

        this.$api.request('/order/export', {date_range: this.export_date_range}).then(data => {
          export_json_to_excel({
            header: data.header, //表头 必填
            data: data.contains, //具体数据 必填
            filename: '数据导出', //非必填
            autoWidth: true, //非必填
            bookType: 'xlsx' //非必填
          })
        })

      }
    }
  }
</script>
